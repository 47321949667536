import { DestroyRef, inject, Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OperationStateModel } from '@app/features/manufactory/models/daily-plans.model';
import { DailyPlanStateService } from '@app/shared/modules/time-tracker/daily-plan-state.service';
import { TimeTrackerNewService } from '@app/shared/modules/time-tracker/service/time-tracker-new.service';

export enum ManufacturingOperationState {
  Ready = '1',
  Start = '3',
  Continue = '6',
  Pause = '4',
  Stop = '8',
}

@Injectable({
  providedIn: 'root',
})
export class DailyPlanListenerService {
  private readonly timeTrackerService = inject(TimeTrackerNewService);
  private readonly dailyPlanStateService = inject(DailyPlanStateService);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    this.timeTrackerService.onStart$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(({ id, state }) => this.dailyPlanStateService.getCurrentOperationState(id, state)),
        switchMap(({ operation, param }) =>
          this.dailyPlanStateService.updateOperationState(
            operation!.id!,
            this.dailyPlanStateService.getNextOperationState(
              operation?.state as unknown as OperationStateModel,
              param ? 'continue' : 'start',
            ),
          ),
        ),
      )
      .subscribe();

    this.timeTrackerService.onPause$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(({ id }) => this.dailyPlanStateService.getCurrentOperationState(id)),
        switchMap(({ operation }) =>
          this.dailyPlanStateService.updateOperationState(
            operation!.id!,
            this.dailyPlanStateService.getNextOperationState(operation?.state as unknown as OperationStateModel, 'pause'),
          ),
        ),
      )
      .subscribe();

    this.timeTrackerService.onStop$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(({ id }) => this.dailyPlanStateService.getCurrentOperationState(id)),
        switchMap(({ operation }) =>
          this.dailyPlanStateService.updateOperationState(
            operation!.id!,
            this.dailyPlanStateService.getNextOperationState(operation?.state as unknown as OperationStateModel, 'stop'),
          ),
        ),
      )
      .subscribe();
  }
}
