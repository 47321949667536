import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/modules/auth/guards/auth.guard';
import { translationResolver } from '@core/resolvers/translation.resolver';
import { cartTranslationResolver } from '@core/resolvers/cart-translation.resolver';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@core/modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'error',
    canActivate: [AuthGuard],
    loadChildren: () => import('@core/modules/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'logistics/cart-scanner',
    loadChildren: () => import('@features/logistics/cart-scanner/cart-scanner.module').then(m => m.CartScannerModule),
    data: {
      title: 'logistics.cart',
      translatePath: 'logistics',
    },
    resolve: {
      translation: cartTranslationResolver,
    },
  },

  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('@core/modules/layout/layout.module').then(m => m.LayoutModule),
    data: {
      translatePath: 'common',
    },
    resolve: {
      translation: translationResolver,
    },
  },
  {
    path: 'dispensing',
    data: {
      translatePath: 'common',
    },
    resolve: {
      translation: translationResolver,
    },
    loadChildren: () => import('@core/modules/dispensing-layout/dispensing-layout.module').then(m => m.DispensingLayoutModule),
  },
  { path: '**', redirectTo: '/error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
